import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import Works from "./works/Works";

const Router = () => (
  <BrowserRouter>
    <Switch>
      {/* exact param of url path is domain */}
      <Route exact path='/' component={App} />
      {/* exact param of url path and variable param / */}
      <Route exact path='/works/:worksId' component={Works} />
    </Switch>
  </BrowserRouter>
);

export default Router;

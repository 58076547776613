import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Summaries from "./Summaries";
import styles from "./intro.module.scss";

const Intro = () => {
  const summaryData = [
    {
      id: 1,
      box: "one",
      sprite: "html5",
      headline: "Front-End development",
      desc: (
        <>
          Specializing in developing a robust user experience. Checkout{" "}
          <a
            href='https://github.com/meijioro'
            target='_blank'
            rel='noopener noreferrer'
            className='white-link'
          >
            code samples
          </a>
          .
        </>
      ),
    },
    {
      id: 2,
      box: "two",
      sprite: "envelope",
      headline: (
        <>
          Responsive Email <br className='mobile-hide' />
          Marketing
        </>
      ),
      desc: "Tailor emails to suit multiple devices and render dynamic data and content.",
    },
    {
      id: 3,
      box: "three",
      sprite: "wordpress",
      headline: (
        <>
          CMS Theming
          <br className='mobile-hide' /> Ninja
        </>
      ),
      desc: "Custom-made themes from Wordpress to Drupal to custom made systems.",
    },
    {
      id: 4,
      box: "four",
      sprite: "tongue",
      headline: (
        <>
          Passionate
          <br className='mobile-hide' /> Fangirl & Nerd
        </>
      ),
      desc: "I've been on a Harry Potter fan trip and attended San Diego Comic Con. Twice!",
    },
  ];

  //get reference data of element
  const ref = useRef(null);
  // //when in view, do only once
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  // const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire animation
      mainControls.start("endState");
      //slideControls.start("endState");
    }
  }, [isInView, mainControls]);

  return (
    <section id='intro' className={`section ${styles.intro}`}>
      <div className='container'>
        <p className='section__lead'>
          <span>
            Creatively charged front-end developer that loves to stay{" "}
            <span className='nowrap'>up-to-date</span> with the latest and
            greatest.{" "}
            <strong>
              If I've piqued your curiosity, let's talk! I never say no to chai
              and conversation.
            </strong>
          </span>
        </p>

        <div className={`grid ${styles.summaries}`}>
          {summaryData.map((summary, index) => (
            <div key={index} ref={ref}>
              <motion.div
                variants={{
                  initialState: { opacity: 0, y: 75 },
                  endState: { opacity: 1, y: 0 },
                }}
                initial='initialState'
                animate={mainControls}
                transition={{
                  duration: 0.5,
                  delay: index * 0.25,
                  ease: "easeInOut",
                }}
              >
                <Summaries
                  key={summary.box}
                  box={summary.box}
                  sprite={summary.sprite}
                  headline={summary.headline}
                  desc={summary.desc}
                />
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Intro;

import React from "react";
import Reveal from "../Reveal";
import styles from "./hero.module.scss";

class Hero extends React.Component {
  state = {
    startDisplay: false,
  };

  componentDidMount() {
    //show
    setTimeout(() => {
      this.setState({ startDisplay: true });
    }, 3600);
  }

  render() {
    const titles = [
      "Front-End Developer",
      "Email Developer",
      "UI Developer",
      "Travel Enthusiast",
      "Front-end Developer",
    ];

    return (
      <section id='hero' className={styles.hero}>
        <h1 className='hidden-content'>Kim Le Interactive</h1>
        <div className='container'>
          <div className={`${styles["content-layer"]}`}>
            <svg
              className={styles["logo-icon-animate"]}
              viewBox='0 -3 118 118'
              role='presentation'
            >
              <path
                className={styles["logo-animate"]}
                fill='currentColor'
                d='M43 60c-3 0-5 4-5 9s2 9 5 9c4 0 6-4 6-9s-2-9-6-9M54 8c11 0 22 4 30 13l6-5A48 48 0 0054 0C43 0 31 5 22 13l5 6c8-7 17-11 27-11'
              />
              <path
                className={styles["logo-animate"]}
                fill='currentColor'
                d='M105 76l-2 2V52l2 1v23zm-50 30c-20 0-37-18-37-41 0-16 8-29 20-36 0 0 21 6 32 27l1 4h-1c-3 0-5 4-5 9s2 9 5 9h1l-4 15s14-6 25-16c-5 17-20 29-37 29zm52-55l-1-1c-2-11-6-22-14-31l-5 5c6 7 10 16 11 26-6-19-23-33-43-33-19 0-36 14-42 33h-3c2-10 7-20 13-27l-6-5C9 27 4 39 3 52H2l-1 1-1 1v27h3V66h4v17h7c7 18 23 30 41 30 20 0 37-13 43-32h5l4-3V52v-1z'
              />
            </svg>

            <div
              className={styles.blurb}
              style={{ display: this.state.startDisplay ? "block" : "none" }}
            >
              <Reveal>
                <h2 className={styles.name}>
                  Hello, I'm Kim<span style={{ color: "#a81f6e" }}>.</span>
                </h2>
              </Reveal>
              <Reveal>
                <p className={styles.dev}>
                  I'm a{" "}
                  <span className={styles.jobtitle}>
                    {titles.map((title, i) => (
                      <span key={i}>{title}.</span>
                    ))}
                  </span>
                </p>
              </Reveal>
              <Reveal>
                <p className={styles.lead}>
                  I'm a front end developer who strives to create frictionless
                  user experiences. My experiences include CMS web development,
                  email development and css layouts. Design? Develop? Done and
                  done.
                </p>
              </Reveal>
              <Reveal>
                <a
                  href='mailto:kimleonline42@gmail.com'
                  rel='noopener noreferrer'
                  className='btn btn_white'
                >
                  Let's connect!
                </a>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;

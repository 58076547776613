import React from "react";
import Nav from "./nav/Nav";
import Hero from "./hero/Hero";
import Intro from "./intro/Intro";
import Gallery from "./gallery/Gallery";
import Skills from "./skills/Skills";
import Omake from "./omake/Omake";

class App extends React.Component {
  const;
  render() {
    return (
      <>
        <Nav delay='3.2s' />
        <Hero />
        <Intro />
        <Gallery />
        <Skills />
        <Omake />

        <footer className='copyright'>
          <span>&copy; {new Date().getFullYear()} Kim Le Interactive</span>{" "}
          Handcrafted with ❤
        </footer>
      </>
    );
  }
}

export default App;

import React from "react";
import Nav from "../nav/Nav";
import Reveal from "../Reveal";

import works from "../../portfolio-list";
import styles from "./works.module.scss";

const Works = (props) => {
  const worksId = props.match.params.worksId;

  const currentWork = works.filter((item) => {
    if (worksId === item.id) {
      return true;
    }
    return false;
  });

  return (
    <main>
      <Nav delay='.5s' />

      <article>
        <header className={styles.header}>
          <div className={styles["back-btn"]}>
            {" "}
            <Reveal>
              <a href='/'>Back</a>
            </Reveal>
          </div>
          <Reveal>
            <h1 className={styles.headline}>{currentWork[0].title}</h1>
          </Reveal>

          <div style={{ margin: "50px 0 -50px" }}>
            <img
              src={`/images/hero/${currentWork[0].id}.png`}
              srcSet={`/images/hero/${currentWork[0].id}.webp 1x, /images/hero/${currentWork[0].id}@2x.webp 2x`}
              alt={currentWork[0].title}
              width={currentWork[0].imageSize.width}
              height={currentWork[0].imageSize.height}
            />
          </div>
        </header>

        <section className={styles.bleed}>
          <div className='container'>
            <div className={styles.grid}>
              <div>
                <h3 className={styles.title}>description</h3>
                <p>{currentWork[0].description}</p>
                <h3 className={styles.title}>my role</h3>
                <p>{currentWork[0].role}</p>

                <p>
                  {currentWork[0].links.map((link, i) => {
                    return (
                      <a href={link.url} key={i} className='btn btn_white'>
                        {link.text}
                      </a>
                    );
                  })}
                </p>
              </div>

              <div>
                <h3 className={styles.title}>technology</h3>
                <ul className={styles.techlist}>
                  {currentWork[0].skills.map((skill, i) => (
                    <li key={`skill${i}`}>{skill}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        {currentWork[0].screenshots.length > 0 ? (
          <section className='section'>
            <div className={`container ${styles["work-grid"]}`}>
              {currentWork[0].screenshots.map((img, index) => (
                <div key={`screenshot${index}`}>
                  <img
                    src={`/images/screenshots/${currentWork[0].id}_${img}.jpg`}
                    srcSet={`/images/screenshots/${currentWork[0].id}_${img}.webp 1x, /images/screenshots/${currentWork[0].id}_${img}@2x.webp 2x`}
                    alt=''
                  />
                </div>
              ))}
            </div>
          </section>
        ) : (
          ""
        )}
      </article>

      <footer className='copyright'>
        <span>&copy; {new Date().getFullYear()} Kim Le Interactive</span>{" "}
        Handcrafted with ❤
      </footer>
    </main>
  );
};

export default Works;

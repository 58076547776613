import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Reveal from "../Reveal";

import styles from "./omake.module.scss";

const Omake = () => {
  const list = [
    {
      sprite: "sunglasses",
      text: "I taught myself how to create websites in 6th grade. Remember Geocities?",
    },
    {
      sprite: "wand",
      text: "I went on a Harry Potter fan trip to the UK and stayed for the last book release.",
    },
    {
      sprite: "devil",
      text: `I sold my soul to Disney World for 6 months. "Have a Disney day" is not what you think it means.`,
    },
    {
      sprite: "film",
      text: "My all-time favorite movie is Empire Strikes Back. R2-D2 is a spunky little dude.",
    },
    {
      sprite: "airplane",
      text: "I love traveling. Travel hacking FTW!",
    },
    {
      sprite: "heart",
      text: "My passion for creating websites started with my love of anime and manga.",
    },
    {
      sprite: "quote",
      text: "I am fluent in English and in Vietnamese.",
    },
    {
      sprite: "food",
      text: "I am a foodie. I'm susceptible to places on Instagram.",
    },
  ];

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire animation
      mainControls.start("endState");
    }
  }, [isInView, mainControls]);

  return (
    <section className='section'>
      <div className='container'>
        <h2 className='section__heading'>
          <Reveal>
            <span>omake info</span>
          </Reveal>
        </h2>
        <p className='section__lead'>
          <Reveal>
            <span>Just some tidbits on what makes me tick.</span>
          </Reveal>
        </p>

        <ul className={`grid ${styles["grid--omake"]}`}>
          {list.map((item, index) => (
            <motion.li
              variants={{
                initialState: { opacity: 0, y: 50 },
                endState: { opacity: 1, y: 0 },
              }}
              initial='initialState'
              animate={mainControls}
              transition={{
                duration: 0.5,
                delay: index * 0.3,
                ease: "easeOut",
              }}
              key={index}
              ref={ref}
              className={styles.item}
            >
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  role='img'
                >
                  <use xlinkHref={`/images/sprite.svg#${item.sprite}`}></use>
                </svg>
              </div>
              <p className={styles.summary}>{item.text}</p>
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Omake;

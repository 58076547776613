import React from "react";
import styles from "./nav.module.scss";

const Nav = (props) => (
  <div className={styles.nav} style={{ animationDelay: props.delay }}>
    <svg width='40' height='40' viewBox='0 -3 118 118' role='presentation'>
      <path
        fill='currentColor'
        d='M43 60c-3 0-5 4-5 9s2 9 5 9c4 0 6-4 6-9s-2-9-6-9M54 8c11 0 22 4 30 13l6-5A48 48 0 0054 0C43 0 31 5 22 13l5 6c8-7 17-11 27-11'
      />
      <path
        fill='currentColor'
        d='M105 76l-2 2V52l2 1v23zm-50 30c-20 0-37-18-37-41 0-16 8-29 20-36 0 0 21 6 32 27l1 4h-1c-3 0-5 4-5 9s2 9 5 9h1l-4 15s14-6 25-16c-5 17-20 29-37 29zm52-55l-1-1c-2-11-6-22-14-31l-5 5c6 7 10 16 11 26-6-19-23-33-43-33-19 0-36 14-42 33h-3c2-10 7-20 13-27l-6-5C9 27 4 39 3 52H2l-1 1-1 1v27h3V66h4v17h7c7 18 23 30 41 30 20 0 37-13 43-32h5l4-3V52v-1z'
      />
    </svg>

    <nav>
      <ul className={styles.social}>
        <li>
          <a href='https://www.linkedin.com/in/kimleonline/'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              role='img'
            >
              <use xlinkHref='../../images/sprite.svg#linkedin'></use>
            </svg>
          </a>
        </li>
        <li>
          <a href='https://github.com/meijioro/'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              role='img'
            >
              <use xlinkHref='../../images/sprite.svg#github'></use>
            </svg>
          </a>
        </li>
        <li>
          <a href='mailto: kimleonline42@gmail.com'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              role='img'
            >
              <use xlinkHref='../../images/sprite.svg#email'></use>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
);

export default Nav;

import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Reveal from "../Reveal";

import styles from "./skills.module.scss";

const Skills = () => {
  const data = [
    {
      skill: "CSS/Sass",
      level: 6,
    },
    {
      skill: "Javascript",
      level: 6,
    },
    {
      skill:
        "Responsive Emails (ExactTarget, Mailchimp, Sailthru, Responsys, Epsilon PCM, etc)",
      level: 6,
    },
    {
      skill: "CMS Theming (Wordpress, Drupal, CQ5, Custom CMS)",
      level: 6,
    },
    {
      skill: "CSS Grid/Flexbox",
      level: 5.5,
    },
    {
      skill: "Vue",
      level: 4.3,
    },
    {
      skill: "React",
      level: 4.3,
    },
    {
      skill: "Git/SVN",
      level: 6,
    },
    {
      skill: "Handlebars",
      level: 6,
    },
    {
      skill: "PHP",
      level: 4,
    },
    {
      skill: "Mocha/Chai",
      level: 3.7,
    },
    {
      skill: "Accessibility",
      level: 6,
    },
    {
      skill: "Gulp",
      level: 3.6,
    },
    {
      skill: "Sketch/XD",
      level: 4.5,
    },
    {
      skill: "Photoshop",
      level: 6,
    },
  ];

  //get reference data of element
  const ref = useRef(null);
  let containerWidth = null;
  if (ref.current) {
    containerWidth = ref.current.clientWidth / 6;
  }

  //when in view, do only once
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      //fire animation
      mainControls.start("endState");
    }
  }, [isInView, mainControls]);

  return (
    <section className={`${styles.skills} section`}>
      <div className='container'>
        <h2 className='section__heading'>
          <Reveal>
            <span>skills</span>
          </Reveal>
        </h2>
        <p className='section__lead'>
          <Reveal>
            <span>
              Some of my technical skills. You can call me the everything bagel.
            </span>
          </Reveal>
        </p>

        <div className={styles.skills__graph}>
          <ul
            className={styles["bar-graph"]}
            aria-label='skills bar chart from level 1 to 6'
            ref={ref}
          >
            {data.map((item, i) => (
              <motion.li
                variants={{
                  initialState: { opacity: 0, y: 30, width: 10 },
                  endState: {
                    opacity: 1,
                    y: 0,
                    width: item.level * containerWidth,
                  },
                }}
                initial='initialState'
                animate={mainControls}
                transition={{
                  duration: 0.3,
                  delay: i * 0.25,
                  ease: "easeInOut",
                }}
                key={i}
                className={styles.bar}
              >
                {item.skill}
              </motion.li>
            ))}
          </ul>

          <div
            className={`${styles.skills__line} ${styles["skills__line--basic"]}`}
            role='presentation'
          >
            <span className={styles.skills__label}>Familiar</span>
          </div>
          <div
            className={`${styles.skills__line} ${styles["skills__line--average"]}`}
            role='presentation'
          >
            <span className={styles.skills__label}>Intermediate</span>
          </div>
          <div
            className={`${styles.skills__line} ${styles["skills__line--proficient"]}`}
            role='presentation'
          >
            <span className={styles.skills__label}>Proficient</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;

import React from "react";
import Reveal from "../Reveal";
import styles from "./gallery.module.scss";
import works from "../../portfolio-list";

class Gallery extends React.Component {
  render() {
    return (
      <section className='section'>
        <div className='arrow arrow--blue'></div>

        <div className='container'>
          <h2 className='section__heading'>
            <Reveal>
              <span>my works</span>
            </Reveal>
          </h2>

          <p className='section__lead'>
            <Reveal>
              <span>
                Writing clean, elegant and efficient code. The proof of my
                existence.
              </span>
            </Reveal>
          </p>

          <div className={`grid ${styles["works-list"]}`}>
            {works.map((item) => (
              <a
                href={`/works/${item.id}`}
                key={item.id}
                tabIndex='0'
                className={styles.work}
              >
                <div>{item.title}</div>
                <picture>
                  <source
                    media='(max-width: 767px)'
                    srcSet={`/images/thumbs/${item.id}_mobile.webp`}
                    type='image/webp'
                  />
                  <source
                    media='(min-width: 768px)'
                    srcSet={`/images/thumbs/${item.id}.webp 1x, /images/thumbs/${item.id}@2x.webp 2x`}
                    type='image/webp'
                  />
                  <img
                    src={`/images/thumbs/${item.id}.jpg`}
                    type='image/jpeg'
                    alt={item.title}
                    className={styles.skeleton}
                  />
                </picture>
              </a>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Gallery;

import React from "react";
import styles from "./intro.module.scss";

const Summaries = (props) => (
  <div className={`${styles.box} box-fade-in ${props.box}`}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={styles.icon}
      width='75'
      height='30'
      role='img'
    >
      <use xlinkHref={`/images/sprite.svg#${props.sprite}`}></use>
    </svg>
    <div className={styles.desc}>
      <span className={styles.title}>{props.headline}</span>
      <p>{props.desc}</p>
    </div>
  </div>
);

export default Summaries;

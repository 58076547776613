import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import PropTypes from "prop-types";

const Reveal = ({ children }) => {
  //get reference data of element
  const ref = useRef(null);
  //when in view, do only once
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire animation
      mainControls.start("endState");
      slideControls.start("endState");
    }
  }, [isInView, mainControls, slideControls]);

  return (
    <div ref={ref} className='reveal'>
      <motion.div
        variants={{
          initialState: { opacity: 0, y: 75 },
          endState: { opacity: 1, y: 0 },
        }}
        initial='initialState'
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25, ease: "easeInOut" }}
      >
        {children}
      </motion.div>
      <motion.div
        variants={{
          initialState: { left: 0 },
          endState: { left: "100%" },
        }}
        initial='initialState'
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn" }}
        className='band'
      />
    </div>
  );
};

export default Reveal;

Reveal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};

const works = [
  {
    id: "module",
    title: "Module Generator",
    imageSize: {
      width: 833,
      height: 448,
    },
    description:
      "This is a generator which creates a quick starter boilerplate for the team when creating custom modules. This also helps users with less advanced coding knowledge.",
    role: "When I started, I noticed a lot of manual work that could be cut down. Originally the process was to either duplicate an existing module or copy from a spreadsheet. The generator optimizes process, standardizes code and cuts down on bugs.",
    skills: [
      "VueJS",
      "Javascript",
      "Bootstrap",
      "Handlebars",
      "PCM Visual Editor",
    ],
    links: [
      {
        text: "Demo",
        url: "https://module-generator-dist.glitch.me/",
      },
      {
        text: "Github",
        url: "https://github.com/meijioro/module-generator",
      },
    ],
    screenshots: [],
  },
  {
    id: "brandstyle",
    title: "Brand Style Generator",
    imageSize: {
      width: 833,
      height: 448,
    },
    description:
      "This is a generator which creates a quick JSON file in the same format Visual Editor uses.",
    role: "To create a user interface for non-developers to be able to create a brand style file to upload to PCM.",
    skills: ["VueJS", "Javascript", "Bootstrap", "JSON", "PCM Visual Editor"],
    links: [
      {
        text: "Demo",
        url: "https://json-generator.glitch.me/",
      },
      {
        text: "Github",
        url: "https://github.com/meijioro/json-generator",
      },
    ],
    screenshots: [],
  },
  {
    id: "bonvoy",
    title: "Marriott Bonvoy v2.0",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "After working within PCM's Visual Editor for sometime, the client had a request to have the templates and modules rebuilt to address user experience issues with the previous build. Since the client had multiple business units but have the same header and footer design, we convinced them to also consolidate all headers and footers into one.",
    role: "Consolidated all 20+ templates into one to support multiple languages, different brand layouts, and email types. All of these are data driven from reference tables so any future updates easy and quick.",

    skills: [
      "Handlebars",
      "Freemarker Scripting",
      "PCM Visual Editor",
      "Reference Tables",
    ],
    links: [],
    screenshots: ["desktop1", "desktop2", "desktop3", "desktop4", "mobile"],
  },

  {
    id: "decisioning",
    title: "Marriott Decisioning",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "This was a pilot program which programmatically mix and match messaging depending on data from the audience list.",
    role: "Hooked up campaign to programmatically pull in different messaging from reference tables depending on the recipient's data in the list. It was set up to be easily scalable and updatable. Going forward, the user does not need to touch the template.",

    skills: [
      "Handlebars",
      "Freemarker Scripting",
      "PCM Visual Editor",
      "Reference Tables",
    ],
    links: [],
    screenshots: ["desktop1", "desktop2", "desktop3", "desktop4", "mobile"],
  },

  {
    id: "ace",
    thumb: "ace.jpg",
    title: "Ace Hardware",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "Onboarding of Ace Hardware which included customized templates and editable customized modules. The client needed versions for both national and local stores. The local stores are more locked down in terms of what they can edit.",
    role: "Create 40+ user editable custom modules and blocks which dynamically pull in data depending on recipient's information. A master template is built for users to mix and match modules as needed.",
    skills: [
      "Handlebars",
      "Freemarker Scripting",
      "PCM Visual Editor",
      "Reference Tables",
    ],
    links: [],
    screenshots: ["desktop1", "desktop2", "desktop3", "mobile"],
  },

  {
    id: "slate",
    thumb: "slate.png",
    title: "Slate Magazine",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "Slate was going through a rebrand and one of the initiatives was to do a redesign of the website while migrating to a new content management system.",
    role: "Work on building components and templates which pull in data from Redis.",
    skills: [
      "Handlebars",
      "Javascript",
      "Sass",
      "Yaml",
      "Mocha, Chai",
      "Accessibility",
      "Tracking",
      "Git",
      "Sketch",
      "Clay CMS",
      "Docker",
    ],
    links: [
      {
        text: "Slate.com",
        url: "https://slate.com/",
      },
    ],
    screenshots: ["desktop1", "desktop2", "desktop3", "mobile"],
  },

  {
    id: "podcast",
    title: "Slate Podcasts",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "This project is part of an entire branding and redux overhaul of Slate. And part of that was to create a bigger presence to Slate's podcasts.",
    role: "Work on building components and templates which pull in data from Redis.",
    skills: ["Handlebars", "Javascript", "Clay CMS", "Yaml", "Megaphone"],
    links: [
      {
        text: "Slate Podcasts",
        url: "https://slate.com/podcasts",
      },
    ],
    screenshots: ["desktop1", "desktop2", "desktop3", "desktop4", "mobile"],
  },

  {
    id: "hotseats",
    title: "Slate Hot Seats",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "The stakeholders wanted to create a series of articles on the election of 2018 called The Hot Seats. The requirements were to have a different layout in which the writer may choose from. This specific layout would also have an automatic email newsletter portion with replicated content.",
    role: "Take Sketch mockups from designers and convert them to create an original layout with editable components for the user to choose from. From there, create the responsive email version of the article automatically so the user would not have to enter in content again.",
    skills: [
      "CSS/Sass",
      "Handlebars",
      "Javascript",
      "Responsive Email",
      "Yaml",
      "Clay CMS",
    ],
    links: [
      {
        text: "The Hot Seats",
        url: "https://slate.com/tag/the-hot-seats",
      },
    ],
    screenshots: ["desktop1", "email"],
  },

  {
    id: "uber",
    title: "SPG | Uber",
    imageSize: {
      width: 1000,
      height: 567,
    },
    description:
      "Starwood Hotel partnered with Uber in a campaign with included email and web elements. Targeted emails would drive users to the website to connect their SPG account with their Uber account to receive 1 Starpoint every time they use Uber.",
    role: "Build both the email and website portion. Both must have flexible templates to allow for multiple languages and different content data to flow into. The template for the website was built inside the Coldfusion MVC framework.",
    skills: [
      "CSS/Sass",
      "Bootstrap",
      "Javascript",
      "Coldfusion",
      "Multi-language",
      "Responsive Emails",
    ],
    links: [
      {
        text: "Email Demo",
        url: "https://www.kimleonline.com/emails/starwood_uber_email/index.php",
      },
    ],
    screenshots: ["desktop1", "desktop2", "desktop3", "desktop4", "mobile"],
  },
];

export default works;
